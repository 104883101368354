exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modal-neighborhoods-js": () => import("./../../../src/pages/modal/neighborhoods.js" /* webpackChunkName: "component---src-pages-modal-neighborhoods-js" */),
  "component---src-pages-promotion-js": () => import("./../../../src/pages/promotion.js" /* webpackChunkName: "component---src-pages-promotion-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-teaching-js": () => import("./../../../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-course-page-js": () => import("./../../../src/templates/course-page.js" /* webpackChunkName: "component---src-templates-course-page-js" */),
  "component---src-templates-esslli-page-js": () => import("./../../../src/templates/esslli-page.js" /* webpackChunkName: "component---src-templates-esslli-page-js" */),
  "component---src-templates-short-course-list-js": () => import("./../../../src/templates/short-course-list.js" /* webpackChunkName: "component---src-templates-short-course-list-js" */)
}

